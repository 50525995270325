import React, { forwardRef } from 'react';
import { Button } from '../UI/Button';
import learnMore from '../../static/learnMore.png';
import logo from '../../static/logo.png'

import fb from '../../static/fb.png'
import ig from '../../static/ig.png'
import ld from '../../static/ld.png'
import yt from '../../static/yt.png'
import localization from '../../static/localization.png'


const Footer = forwardRef((props: any, ref: any) => {
    return (
      <div className="flex flex-col gap-6 justify-center items-center font-Lato py-4 px-4 lg:px-44 lg:py-24  text-center " >
        <div className='flex flex-1 w-full gap-8 border-b py-8 border-secondary-700 items-center justify-center text-center border-opacity-20 lg:text-left'>
            <div className='flex flex-col lg:flex-row gap-6 items-center justify-center flex-1 lg:justify-between'>
                <div className='flex flex-col items-center gap-3 lg:items-start'>
                    <img src={logo} width={134}/>
                    <p className='text-[0.875rem] font-thin max-w-[55%]'>Profesjonalny system analityczny dla producentów i wydawców</p>
                </div>
                
                <div className='flex flex-col justify-center items-center gap-4 lg:items-start'>
                    <p className='text-[0.875rem] max-w-[100%] flex'><img src={localization} className='hidden lg:block'/>ETICOD sp. z o.o. ul. Grzegorzka 21, 40-309 Katowice</p>
                    <div className='flex justify-center  gap-4 '>
                        <img src={fb} />
                        <img src={ld} />
                        <img src={yt} />
                        <img src={ig} />
                    </div>
                </div>
            </div>
        </div>
        <div className='flex flex-col gap-4 lg:flex-row lg:justify-between lg:w-full'>
            <div className='flex justify-around gap-4'>
                <p className='text-secondary-500 text-[0.875rem] hover:cursor-pointer' onClick={props.terms}>Regulamin</p>
                <p className='text-secondary-500 text-[0.875rem] hover:cursor-pointer' onClick={props.privacyPolicy}>Polityka prywatności</p>
            </div>
            <p className='text-secondary-500 text-[0.875rem] opacity-50'>Wszystkie prawa zastrzeżone © 2024 • ETICOD sp. z o.o.</p>
        </div>
      </div>
    );
  });
  
  export default Footer;