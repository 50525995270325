import React, { forwardRef, useState } from 'react';
import Tile from './Tile';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';
import MarketingModal from './MarketingModal';
const Marketing = forwardRef((props: any, ref: any) => {

  const { ref:reference, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

    return (
      <div ref={ref}>
      <div ref={reference}>
        <Fade bottom duration={1500}  when={inView} >
        <div className="flex flex-col gap-4 lg:justify-center lg:items-center font-Lato px-4 lg:px-10 " >
          <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold mb-2'>Wykorzystaj siłę automatyzacji marketingu!</h2>
          <div className='flex flex-col gap-8 lg:grid lg:grid-cols-3 '>
              <Tile header={"Kilka kliknięć wystarczy, aby utworzyć stronę produktową!"} text={"Skorzystaj z kreatora szablonów, aby błyskawicznie utworzyć personalizowane strony internetowe."}
              buttonText={"Dowiedz się więcej"} onClick={handleOpen} />
              <Tile header={"Rozbuduj własną bazę marketingową"} text={"Zachęć użytkowników do sprawdzenia dodatkowych informacji o produkcie. Masz kontrolę nad tym, jakie informacje są widoczne dla wszystkich użytkowników, a jakie tylko dla tych zarejestrowanych."}
              buttonText={"Dowiedz się więcej"} onClick={handleOpen} />
              <Tile header={"Udostępniaj informacje o promocjach!"} text={"Wykorzystaj przestrzeń marketingową do tego, aby poinformować swoich klientów o nowych promocjach! "}
              buttonText={"Dowiedz się więcej"}  onClick={handleOpen} />
          </div>
        </div>
        </Fade>
      </div>
    <MarketingModal open={open} handleClose={handleClose} contact={props.contact} number={2}/>
      </div>
    );
  });
  
  export default Marketing;