import React, { forwardRef } from 'react';
import { Button } from '../UI/Button';



const Tile = forwardRef((props: any, ref: any) => {
  if(props.type === 1){
    return (
      <div className="flex flex-col justify-between gap-24 p-4 pb-8 font-Lato bg-gray-900 rounded-[2rem] w-full min-h-[27rem] lg:max-h-[27rem] lg:min-w-[27rem] lg:max-w-[27rem] " >
        <div className='flex flex-col gap-2'>
            <h3 className='text-[1.75rem] lg:text-[2rem] font-semibold leading-[110%]'> {props.header}</h3>
            <p className='text-[1.125rem]'>
                {props.text}
            </p>
        </div>

         <Button text={props.buttonText} reference={props.reference} onClick={props.onClick}/>
      </div>
    );
  }
  else{
    return (
      <div className="flex flex-col justify-between gap-24 p-4 pb-8 font-Lato bg-gray-900 rounded-[2rem] min-h-[22.5rem] lg:max-w-[27rem] " >
        <div className='flex flex-col gap-2'>
            <h3 className='text-[1.75rem] lg:text-[2rem] font-semibold leading-[110%]'> {props.header}</h3>
            <p className='text-[1.125rem]'>
                {props.text}
            </p>
        </div>

         <Button text={props.buttonText} reference={props.reference} onClick={props.onClick}/>
      </div>
    );
  }
    
  });
  
  export default Tile;