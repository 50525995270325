import React, { forwardRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';

const PrivacyPolicy = forwardRef((props: any, ref: any) => {

  const { ref:reference, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

    return (
      <div ref={ref}>
      <div ref={reference}>
        <Fade bottom duration={1500}  when={inView} >
        <div className="flex flex-col gap-4  font-Lato px-4 lg:px-10 text-[1rem] bg-gray-900 py-10" >
          <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold mb-2 text-center'>Polityka prywatności</h2>
           <div><h3 className="font-bold">Polityka prywatności DEFINICJE</h3>
            <p>Dane Osobowe oznaczają wszelkie informacje dotyczące osoby fizycznej, która została zidentyfikowana lub której tożsamość można ustalić bezpośrednio lub pośrednio na podstawie jednego lub większej liczby specyficznych czynników charakteryzujących jej fizyczne, fizjologiczne, genetyczne, psychiczne, ekonomiczne, kulturowe lub społeczne cechy. W szczególności termin ten obejmuje adres IP używanego urządzenia, dane dotyczące lokalizacji, identyfikator online oraz informacje zbierane za pomocą plików cookie i innych porównywalnych technologii śledzenia.
            </p></div>
            <div><h3 className="font-bold">Polityka oznacza przedmiotowy dokument określany jako Polityka Prywatności.</h3>
            <p>RODO Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                Konto definiuje się jako konto Uczestnika, które umożliwia korzystanie z Serwisu oraz logowanie się za pośrednictwem strony internetowej o adresie https://adaptivo.io Konto to umożliwia korzystanie z usług oferowanych na wskazanej stronie, zgodnie z postanowieniami Regulaminu korzystania z usługi
            </p>
            <p>
            Serwis serwis internetowy prowadzony przez Administratora pod adresem: https://adaptivo.io <br />
Usługa zbiór funkcjonalności udostępnionych za pośrednictwem Platformy, które umożliwiają Użytkownikowi Zarejestrowanemu dostęp do katalogu zawierającego rozszerzone informacje dotyczące produktów skanowanych przez Użytkownika oraz wykaz ostatnio skanowanych produktów przez Użytkownika.
Użytkownik termin ten odnosi się do każdej osoby fizycznej, która odwiedza Serwis lub korzysta z jednej bądź wielu usług lub funkcjonalności opisanych w niniejszej Polityce.
            </p>
            <p>Administrator spółka pod firmą ETICOD sp. z o.o. z siedzibą w Katowicach (40-309), ul. Grzegorzka 21, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Katowice-Wschód w Katowicach, VIII Wydział Gospodarczy KRS pod numerem KRS 0000321577, o kapitale zakładowym ( uzupełnić ), NIP: 627 266 37 23, REGON: 241087158.
Konto konto Uczestnika umożliwiające korzystanie z serwisu oraz logowanie za pośrednictwem strony https://www.adaptivo.io , w tym korzystanie z usług udostępnionych na stronie zgodnie z Regulaminem.
Użytkownik Zarejestrowany osoba pełnoletnia, która dokonała rejestracji i utworzyła Konto w serwisie adaptivo.io , uprawniające do korzystania z Usługi.
</p>
</div>
<div>
    <h3 className="font-bold">PRZETWARZANIE DANYCH W ZWIĄZKU Z KORZYSTANIEM Z SERWISU</h3>
<p>W kontekście korzystania przez Użytkownika z Serwisu, Administrator gromadzi dane w zakresie niezbędnym do realizacji świadczenia poszczególnych oferowanych usług, jak również informacje dotyczące aktywności Użytkownika w ramach Serwisu. Poniżej przedstawiono szczegółowe zasady oraz cele przetwarzania danych osobowych, które są gromadzone w trakcie korzystania z Serwisu przez Użytkownika.
<br />CELE ORAZ PODSTAWY PRAWNE PRZETWARZANIA DANYCH W SERWISIE KORZYSTANIE Z SERWISU</p>
        <ul>
            <li>Dane osobowe wszystkich osób korzystających z Serwisu (w tym adres IP lub inne identyfikatory oraz informacje gromadzone za pośrednictwem plików cookies lub innych podobnych technologii), a niebędących zarejestrowanymi Użytkownikami (tj. osoby nieposiadające profilu w Serwisie) są przetwarzane przez Administratora:</li>
            <li>w celu świadczenia usług drogą elektroniczną w zakresie udostępniania Użytkownikom treści zgromadzonych w Serwisie – wówczas podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO);</li>
            <li> w celach analitycznych i statystycznych – wówczas podstawą prawną przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO) polegający na prowadzeniu analiz aktywności Użytkowników, a także ich preferencji w celu poprawy stosowanych funkcjonalności i świadczonych usług;</li>
            <li> w celu dochodzenia roszczeń lub obrony przed nimi – podstawą prawną przetwarzania jest uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO) polegający na ochronie jego praw;</li>
            <li> w celach marketingowych Administratora – zasady przetwarzania danych osobowych w celach marketingowych zostały opisane w sekcji MARKETING.</li>
            <li> Aktywność Użytkownika w Serwisie, w tym jego dane osobowe, są rejestrowane w logach systemowych (specjalnym programie komputerowym służącym do przechowywania chronologicznego zapisu zawierającego informację o zdarzeniach i działaniach dotyczących systemu informatycznego służącego do świadczenia usług przez Administratora). Zebrane w logach informacje są przetwarzane przede wszystkim w celach związanych ze świadczeniem usług. Administrator przetwarza je również w celach
            tec</li>
        </ul>
        <h2>REJESTRACJA</h2>

<p>Osoby decydujące się na rejestrację są proszone o podanie danych niezbędnych do utworzenia oraz obsługi Konta. W celu ułatwienia obsługi, Użytkownik ma możliwość podania dodatkowych danych, wyrażając w ten sposób zgodę na ich przetwarzanie. Takie dane można w dowolnym momencie usunąć. Podanie danych oznaczonych jako obowiązkowe jest konieczne w celu założenia i obsługi Konta, a ich niepodanie skutkuje brakiem możliwości utworzenia Konta. Podanie pozostałych danych jest dobrowolne.</p>
</div>
<div><h3 className="font-bold">DANE OSOBOWE SĄ PRZETWARZANE</h3>
<ul>
    <li>w celu świadczenia usług związanych z prowadzeniem i obsługą Konta, w szczególności świadczenia Usługi – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO), a w zakresie danych podanych fakultatywnie - podstawą prawną przetwarzania jest zgoda (art. 6 ust. 1 lit. a RODO);</li>
    <li>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na prowadzeniu analiz aktywności Użytkowników w Serwisie i sposobu korzystania z Konta, a także preferencji Użytkowników w celu poprawy stosowanych funkcjonalności;</li>
    <li>w celu ewentualnego ustalania i dochodzenia roszczeń lub obrony przed roszczeniami – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ochronie jego praw;</li>
    <li>w celach marketingowych Administratora oraz innych podmiotów – zasady przetwarzania Danych Osobowych w celach marketingowych zostały opisane w sekcji „MARKETING”.</li>
</ul>
</div>
<div><h3 className="font-bold">FORMULARZE KONTAKTOWE</h3>
<p>Administrator umożliwia nawiązanie kontaktu za pośrednictwem elektronicznych formularzy kontaktowych. Korzystanie z formularza wiąże się z koniecznością podania danych osobowych, które są niezbędne do nawiązania komunikacji z Użytkownikiem oraz udzielenia odpowiedzi na zapytanie. Użytkownik ma także możliwość podania dodatkowych danych, które mogą ułatwić kontakt lub obsługę zapytania. Wprowadzenie danych oznaczonych jako obowiązkowe jest niezbędne do zarejestrowania oraz obsługi zapytania, a ich brak uniemożliwi obsługę. Podanie innych danych jest dobrowolne.</p>
<ul>
    <li>w kontekście formularza kontaktowego – celem obsługi zapytania złożonego za pośrednictwem formularza kontaktowego – podstawą prawną przetwarzania jest niezbędność przetwarzania do realizacji umowy o świadczenie usługi (art. 6 ust. 1 lit b RODO); w zakresie przetwarzania danych fakultatywnych podstawą prawną przetwarzania jest wyrażona zgoda (art. 6 ust. 1 lit. a RODO);</li>
    <li>w zakresie korzystania z któregokolwiek z formularzy – celem dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ochronie jego praw.</li>
</ul>
</div>
<div>
<h3 className="font-bold">MARKETING BIULETYN INFORMACYJNY</h3>
<p>Administrator prowadzi przetwarzanie danych osobowych Użytkowników w ramach realizacji działań marketingowych, mających na celu wysyłanie powiadomień e-mailowych dotyczących atrakcyjnych ofert lub materiałów, które w określonych przypadkach zawierają informacje handlowe. Usługa biuletynu informacyjnego jest oferowana osobom, które podały swój adres e-mail w tym celu. Podanie danych jest konieczne do skorzystania z usługi biuletynu informacyjnego, a ich brak uniemożliwi wysyłkę biuletynu.</p>
<ul>
    <li>kierowania do Użytkownika materiałów marketingowych za pośrednictwem biuletynu informacyjnego – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit f RODO) w związku z wyrażoną zgodą na otrzymywanie biuletynu informacyjnego;</li>
    <li>analiz i statystyk – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na analizie i statystykach, które umożliwiają dopasowanie oferowanych treści i usług do preferencji odbiorców biuletynu informacyjnego;</li>
    <li>ewentualnego ustalania, dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), mający na celu ochronę jego praw.</li>
</ul>
</div>
<div>
<h3 className="font-bold">MEDIA SPOŁECZNOŚCIOWE</h3>
<p>Administrator prowadzi przetwarzanie danych osobowych Użytkowników odwiedzających profile Administratora utworzone w mediach społecznościowych (Facebook, YouTube, Instagram). Przetwarzanie tych danych ma miejsce wyłącznie w
kontekście prowadzenia profilu, w tym w celu informowania Użytkowników o działalności Administratora oraz promowania różnorodnych wydarzeń, usług oraz produktów. Podstawą prawną przetwarzania danych osobowych przez Administratora w tym zakresie jest jego uzasadniony interes (art. 6 ust. 1 lit. f RODO) związany z promocją własnej marki.
</p>
</div>
<div>
<h3 className="font-bold">PLIKI COOKIES I TECHNOLOGIE POWIĄZANE</h3>
<p>Pliki cookies stanowią małe pliki tekstowe zainstalowane na urządzeniu Użytkownika. Cookies gromadzą dane ułatwiające korzystanie z serwisu internetowego – na przykład poprzez zapisywanie informacji o Użytkowniku takich jak dane logowania czy preferencje językowe. Administratorem danych przetwarzanych w kontekście korzystania z plików cookie jest ETICOD sp. z o.o. z siedzibą w Katowicach (40-309), ul. Grzegorzka 21. W Serwisie Administrator stosuje pliki cookies stron trzecich, pochodzące z domeny innej niż domena odwiedzanej witryny, głównie w celach analitycznych i reklamowych Administratora.
Serwis korzysta z plików cookies głównie w celu zapewnienia właściwego działania strony internetowej, zapisywania wyborów dokonanych przez Użytkownika na stronie a także, po uzyskaniu odpowiedniej zgody od Użytkownika, do analizy i monitorowania ruchu w Serwisie oraz dopasowywania treści reklamowych do zainteresowań Użytkownika. W ramach Serwisu, po uzyskaniu zgody, instalowane są również pliki cookies umożliwiające korzystanie z funkcji mediów społecznościowych.
Poniżej prezentowane są szczegółowe informacje dotyczące plików cookies, które Administrator stosuje w Serwisie. Administrator regularnie korzysta z narzędzi skanujących Serwis, aby zidentyfikować pliki cookie przechowywane na urządzeniu Użytkownika, tak aby lista stosowanych plików cookie była jak najbardziej aktualna. Administrator kategoryzuje pliki cookies jako: niezbędne, funkcjonalne, analityczne, reklamowe oraz cookies mediów społecznościowych.
</p>
</div>
<div>
<h3 className="font-bold">NIEZBĘDNE PLIKI COOKIES</h3>
<p>Stosowanie przez Administratora niezbędnych plików cookie jest kluczowe dla prawidłowego funkcjonowania strony internetowej. Pliki te są instalowane przede wszystkim w celu zapamiętywania sesji logowania lub wypełniania formularzy, a także w związku z ustawieniami opcji prywatności.
Podstawą prawną przetwarzania danych w kontekście korzystania z niezbędnych plików cookies jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO).
Jeżeli Użytkownik chce uzyskać więcej informacji na temat poszczególnych plików z tej kategorii, tj. nazwy poszczególnych cookies, opisu działania, okresu ważności i pochodzenia, powinien kliknąć przycisk "Zarządzaj cookies", który umieszczony jest w
punkcie 8 Polityki prywatności lub przycisk o tej samej treści dostępny w stopce każdej podstrony Serwisu. Po wyświetleniu się banera cookie, należy wybrać przycisk "Zarządzaj cookies" i następnie rozwinąć listę "Niezbędne pliki cookie", a potem kliknąć przycisk „Szczegóły” znajdujący się poniżej.
</p>
</div>
<div>
<h3 className="font-bold">FUNKCJONALNE I ANALITYCZNE PLIKI COOKIES</h3>
<p>Funkcjonalne pliki cookies stosowane są z celem zapamiętania i dostosowania Serwisu do preferencji Użytkownika, w tym preferencji językowych. Funkcjonalne pliki cookies mogą być instalowane zarówno przez Administratora, jak i jego partnerów technologicznych za pośrednictwem Serwisu.
Analityczne pliki cookies służą pozyskiwaniu danych takich jak liczba wizyt oraz źródła ruchu w Serwisie. Wykorzystywane są do analizowania popularności poszczególnych stron oraz zrozumienia, w jaki sposób Użytkownicy poruszają się po stronie, co pomaga w prowadzeniu statystyk ruchu w Serwisie. Przetwarzanie danych ma na celu poprawę efektywności Serwisu. Informacje zbierane przez te pliki cookie są agregowane, więc nie służą identyfikacji Użytkownika. Analityczne pliki cookies mogą być instalowane przez Administratora i jego partnerów za pośrednictwem Serwisu.
Podstawą prawną przetwarzania danych osobowych w kontekście korzystania z funkcjonalnych i analitycznych plików cookies przez Administratora, jest jego prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO), polegający na zapewnieniu wysokiej jakości usług świadczonych w Serwisie, zgodnie z wyrażoną przez Użytkownika zgodą na ich zapisywanie (osobną dla plików analitycznych, osobną dla plików funkcjonalnych).
Przetwarzanie danych osobowych w związku z wykorzystaniem funkcjonalnych i analitycznych plików cookies jest uzależnione od uzyskania zgody Użytkownika na ich wykorzystanie (osobno) za pośrednictwem platformy zarządzania zgodą cookies. Zgoda ta może być w każdym momencie wycofana za pośrednictwem tej platformy.
Aby uzyskać więcej informacji na temat poszczególnych plików z tych kategorii tj. nazwy poszczególnych cookies, opisu działania, okresu ważności i pochodzenia, należy kliknąć przycisk "Zarządzaj cookies", umieszczony w punkcie ZARZĄDZANIE USTAWIENIAMI COOKIES Polityki prywatności lub przycisk o tożsamej treści dostępny w stopce Serwisu. Po pojawieniu się bannera cookie, należy wybrać przycisk "Zarządzaj cookies" i następnie rozwinąć listę „Analityczne pliki cookie” lub „Funkcjonalne pliki cookies”, a potem kliknąć przycisk „Szczegóły” znajdujący się pod każdą z list.
</p>
</div>
<div>
<h3 className="font-bold">REKLAMOWE PLIKI COOKIES</h3>
<p>Reklamowe pliki cookies służą dopasowaniu wyświetlanych treści reklamowych do zainteresowań Użytkowników w ramach Serwisu oraz poza nim. Na podstawie
informacji z tych plików cookies i aktywności Użytkownika w innych serwisach, tworzony jest profil zainteresowań Użytkownika. Reklamowe pliki cookies mogą być instalowane przez Administratora i jego partnerów reklamowych za pośrednictwem Serwisu.
Podstawą prawną przetwarzania danych osobowych w kontekście korzystania z reklamowych plików cookies przez Administratora, jest jego prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO), polegający na promowaniu marki Administratora oraz informowaniu o bieżącej ofercie, w tym poprzez kierowanie do Użytkowników informacji marketingowych odpowiadających ich zainteresowaniom, zgodnie z wyrażoną przez Użytkownika zgód.
</p>
</div>
<div>
<h3 className="font-bold">INFORMACJE O NARZĘDZIACH ANALITYCZNYCH I MARKETINGOWYCH</h3>
<p>Administrator wraz z Partnerami korzysta z rozmaitych rozwiązań oraz narzędzi, które są przeznaczone do celów analizy oraz działań marketingowych. Poniżej przedstawiono zarys informacji dotyczących tych narzędzi.<br />
Google Analytics Analiza korzystania z Serwisu i tworzenie statystyk oraz raportów.Polityka prywatności.<br />
Google Tag Manager Zarządzanie skryptami na stronie internetowej, umożliwiające instalację różnych typów skryptów. Polityka prywatności.<br />
Google Ads Pomiar skuteczności kampanii reklamowych i analiza danych związanych z reklamami. Polityka prywatności.<br />
Piksele Facebooka Mierzenie efektywności kampanii reklamowych na Facebooku i analiza danych dla optymalizacji działań marketingowych. Polityka prywatności.</p>
</div>
<div>
<h3 className="font-bold">ZARZĄDZANIE USTAWIENIAMI PLIKÓW COOKIES</h3>
<p>Stosowanie plików cookies celem gromadzenia danych, włączając w to uzyskiwanie dostępu do informacji zmagazynowanych na urządzeniu Użytkownika, uzależnione jest od uzyskania zgody Użytkownika. W Serwisie zgoda ta jest uzyskiwana od Użytkownika poprzez platformę do zarządzania zgodą na pliki cookies. Użytkownik może wycofać swoją zgodę w każdym momencie, zgodnie z zasadami opisanymi w punkcie poniżej.
Zgoda na używanie plików cookies nie jest wymagana wyłącznie w przypadku, gdy ich użycie jest kluczowe do świadczenia usługi telekomunikacyjnej (transmisja danych celem wyświetlenia treści) wtedy Użytkownik nie ma opcji rezygnacji z tych plików cookies, jeśli pragnie korzystać z Serwisu.
Aby otrzymywać reklamy dostosowane do preferencji Użytkownika, oprócz wyrażenia zgody na instalację plików cookies za pośrednictwem platformy zarządzania zgodą na pliki cookies, wymagane jest utrzymanie właściwych ustawień przeglądarki, umożliwiających przechowywanie plików cookies z Serwisu na urządzeniu końcowym Użytkownika.
Anulowanie zgody na gromadzenie plików cookies w Serwisie jest możliwe przez platformę zarządzania zgodą na pliki cookies. Użytkownik może powrócić do banera poprzez kliknięcie w przycisk „Zarządzaj cookies” lub przycisk o analogicznej treści dostępny w stopce każdej podstrony Serwisu.
Po wyświetleniu banera, Użytkownik ma możliwość wycofania zgody poprzez kliknięcie przycisku ZARZĄDZAJ COOKIES. Następnie należy przesunąć suwak przy wybranej kategorii plików cookies i nacisnąć przycisk ZAPISZ USTAWIENIA I ZAMKNIJ
Użytkownik posiada także możliwość wycofania zgody poprzez modyfikację ustawień przeglądarki. Detale na ten temat można znaleźć pod następującymi linkami:
Internet Explorer
https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-ma nage-cookies
Mozilla Firefox
http://support.mozilla.org/pl/kb/ciasteczka
Google Chrome
http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647
Opera
http://help.opera.com/Windows/12.10/pl/cookie.html/
Safari
https://support.apple.com/kb/PH5042?locale=en-GB
Użytkownik może w dowolnym momencie sprawdzić status aktualnych ustawień prywatności dla wykorzystywanej przeglądarki przy użyciu narzędzi dostępnych pod poniższymi linkami:
http://www.youronlinechoices.com/pl/twojewybory
http://optout.aboutads.info/?c=2&lang=EN
Aby skorzystać z praw dostępu, korekty, usunięcia, ograniczenia, transferu, sprzeciwu wobec przetwarzania danych osobowych, zgłoszenia reklamacji lub zadania innych pytań w zakresie plików cookies, prosimy o przesłanie zapytania na adres biuro@eticod.pl lub inne dane kontaktowe Administratora podane w Polityce prywatności.
</p>
</div>
<div>
<h3 className="font-bold">BEZPIECZEŃSTWO DANYCH OSOBOWYCH</h3>
<p>Administrator na bieżąco prowadzi analizę ryzyka w celu zapewnienia, że dane osobowe przetwarzane są przez niego w sposób bezpieczny – zapewniający przede wszystkim, że dostęp do danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu na wykonywane przez nie zadania. Administrator dba o to, by wszystkie operacje na danych osobowych były rejestrowane i dokonywane jedynie przez uprawnionych pracowników i współpracowników.
Administrator podejmuje wszelkie niezbędne działania, by także jego podwykonawcy i inne podmioty współpracujące dawały gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają dane osobowe na zlecenie Administratora.
</p>
</div>
<div>
<h3 className="font-bold">DANE KONTAKTOWE</h3>
<p>Kontakt z Administratorem jest możliwy poprzez adres e-mail: biuro@eticod.pl lub adres korespondencyjny: ETICOD sp. z o.o. z siedzibą w Katowicach (40-309), ul. Grzegorzka 21
</p>
</div>
<div>
<h3 className="font-bold">ZMIANY POLITYKI PRYWATNOŚCI</h3>
<p>Polityka jest na bieżąco weryfikowana i w razie potrzeby aktualizowana. W przypadku aktualizacji Polityki Użytkownik zostanie o tym powiadomiony poprzez wyświetlenie czytelnej informacji w Serwisie lub poprzez wysłanie Użytkownikowi wiadomości
e-mail. W niektórych przypadkach Użytkownik może być powiadomiony z wyprzedzeniem o aktualizacji Polityki, zaś fakt korzystania z usług Serwisu będzie oznaczał akceptację zaktualizowanej wersji Polityki.<br />
Użytkownik, który nie akceptuje warunków świadczenia usług w Serwisie po wejściu w życie nowszej wersji Polityki, może zaprzestać korzystania z usług Serwisu. <br />
Aktualna wersja Polityki została przyjęta i obowiązuje od 27.10.2023</p>
</div>
        </div>
        </Fade>
      </div>
   
      </div>
    );
  });
  
  export default PrivacyPolicy;