import Navbar from './components/Navbar/Navbar';
import Main from './components/Main/Main';
import About from './components/About/About';
import Clients from './components/Clients/Clients';
import Marketing from './components/Marketing/Marketing';
import LearnMore from './components/LearnMore/LearnMore';
import Stats from './components/Stats/Stats';
import Steps from './components/Steps/Steps';
import Contact from './components/Contact/Contact';
import Footer from './components/UI/Footer';
import {createRef, useEffect } from 'react';
import {Fade} from 'react-reveal';
import { useInView } from 'react-intersection-observer';
import { InView } from 'react-intersection-observer';
import Cookies from './components/UI/Cookies';
import {useRef} from 'react';
import close from './static/close.svg';
import modalImage1 from './static/modalImage1.png';
import modalImage2 from './static/modalImage2.png';
import learnMore from './static/learnMore.png';
import { useState } from 'react';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Terms from './components/Terms/Terms';


const COOKIE_ACCEPT_KEY = 'cookieAccept';
const COOKIE_EXPIRATION_DAYS = 30;

function App() {


  const aboutRef =  createRef<HTMLDivElement>();
  const marketingRef = createRef<HTMLDivElement>();
  const statsRef = createRef<HTMLDivElement>();
  const contactRef = createRef<HTMLDivElement>();

  const [showCookies, setShowCookies] = useState(true);
  const [view, setView] = useState(0);

  const handleCloseCookies = () => {
    setShowCookies(false);
  }

  const handleChangeToMain = () => {
    setView(0);
  }

  const handleChangeToPrivacyPolicy = () => {
    window.scrollTo({top:0, behavior: 'smooth'})
    setView(1);
  }
  const handleChangeToTerms = () => {
    window.scrollTo({top:0, behavior: 'smooth'})
    setView(2);
  }

  const hasUserAcceptedCookies = () => {
    const storedCookieAccept = localStorage.getItem(COOKIE_ACCEPT_KEY);
    console.log(storedCookieAccept)
    if (storedCookieAccept === null) {
      
      setShowCookies(true);
    } else {
      
      const storedDate = localStorage.getItem(`${COOKIE_ACCEPT_KEY}_date`);
      if (storedDate) {
        const expirationDate = new Date(storedDate);
        const currentDate = new Date();
        const diffInDays = Math.floor((currentDate.getTime() - expirationDate.getTime()) / (1000 * 60 * 60 * 24));
        if (diffInDays <= COOKIE_EXPIRATION_DAYS) {
          setShowCookies(false);
        } else {
          
          setShowCookies(true);
        }
      }
    }
  }

  useEffect(()=>{
    console.log(window.scrollY)
    
    if(window.scrollY < 20){
      window.scrollTo({ top: 20, behavior: 'smooth' });

    }

    hasUserAcceptedCookies();
    
  },[window.scrollY])



  return (
    
    <div className='flex flex-col text-white gap-8'>
      <Navbar aboutRef={aboutRef} marketingRef={marketingRef} statsRef={statsRef} contactRef={contactRef} handleChangeToMain={handleChangeToMain}/>
      {view === 0 ? <>
      <Main about={aboutRef}/>
      <About ref={aboutRef} contact={contactRef} stats={statsRef}/>
      <Clients contact={contactRef}/>
      <Marketing ref={marketingRef} contact={contactRef}/>
      <LearnMore  contact={contactRef}/>
      <Stats ref={statsRef} contact={contactRef}/>
      <Steps contact={contactRef}/>
      <Contact ref={contactRef}/>

      <img src={close} className='hidden'/>
      <img src={modalImage1} className='hidden'/>
      <img src={modalImage2} className='hidden'/>
      <img src={learnMore} className='hidden'/>
      </>: view === 1 ? <PrivacyPolicy />
       : <Terms />}
      {/*showCookies && <Cookies handleCloseCookies={handleCloseCookies}/>*/}
      <Footer privacyPolicy={handleChangeToPrivacyPolicy} terms={handleChangeToTerms}/>
    </div>
  );
}

export default App;
