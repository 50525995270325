import React, { forwardRef, Ref, RefObject, useEffect } from 'react';
import { useState } from 'react';
import { Fade as Hamburger } from 'hamburger-react'
import logo from '../../static/logo.png'


interface NavbarProps {
 
  view?: string;
  aboutRef: RefObject<HTMLDivElement>;
  marketingRef: RefObject<HTMLDivElement>;
  statsRef: RefObject<HTMLDivElement>;
  contactRef: RefObject<HTMLDivElement>;
  handleChangeToMain: ()=>void;
  // Dodaj inne referencje dla innych komponentów
}

const Navbar = forwardRef<HTMLDivElement, NavbarProps>((props, ref)  => {

 

    const[expanded, setExpanded] = useState(false);
    const [sticky, setSticky] = useState(false);

    


  

    const handleExpand = () => {
        setExpanded(!expanded);
    }

    const [activeSection, setActiveSection] = useState('');

    const scrollToComponent = (componentRef: RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null) => {
      props.handleChangeToMain();
      setExpanded(false)
      if (componentRef && 'current' in componentRef && componentRef.current) {
        
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (typeof componentRef === 'function') {
        // Jeśli komponent jest przekazany jako funkcja ref, wywołujemy ją z null, aby zresetować referencję.
        
        componentRef(null);
      }
    };

    





    return (
      <nav className={`flex flex-col sticky px-4 lg:px-10 min-w-full top-0 py-2 z-20 text-sm text-white bg-black font-OpenSans ${expanded ? "bg-gray-900 rounded-b-[2rem]" : "navbar"} lg:px-10 lg:py-4`}>
        <div className='flex justify-between items-center '>
          <a href='/'><img src={logo} alt="logo"/></a>
          <span className='flex items-center gap-2 lg:gap-8'>
            <ul className='gap-8 hidden items-center lg:flex'>
              <li onClick={()=>scrollToComponent(props.aboutRef)} className={activeSection === 'whoAmI' ? 'activeLink' : 'cursor-pointer'}>Poznaj Adaptivo</li>
              <li onClick={()=>scrollToComponent(props.marketingRef)}  className={activeSection === 'financialbenefits' ? 'activeLink' : 'cursor-pointer'}>Personalizuj treści</li>
              <li onClick={()=>scrollToComponent(props.statsRef)}  className={activeSection === 'cultureAndalusia' ? 'activeLink' : 'cursor-pointer'}>Analiza skanowań</li>
              <li onClick={()=>scrollToComponent(props.contactRef)}  className={activeSection === 'dreamRetirement' ? 'activeLink' : 'cursor-pointer'}>Umów spotkanie</li>
              <a href="https://panel.adaptivo.io/"><li  className="border-2 rounded-[2rem] border-white p-2 cursor-pointer">Zaloguj się do panelu</li></a>
            </ul>
              <span className='lg:hidden'>
                <Hamburger toggled={expanded} toggle={setExpanded} color="#FFF"/>
              </span>
          </span>
        </div>
        {expanded &&
        <ul className='bg-gray-900 py-8 text-center flex flex-col gap-6 select-none items-center rounded-b-[2rem]'>
            <li   onClick={()=>scrollToComponent(props.aboutRef)} >Poznaj Adaptivo</li>
            <li   onClick={()=>scrollToComponent(props.marketingRef)} >Personalizuj treści</li>
            <li onClick={()=>scrollToComponent(props.statsRef)}   >Analiza skanowań</li>
            <li  onClick={()=>scrollToComponent(props.contactRef)}  >Umów spotkanie</li>
            <a href="https://panel.adaptivo.io/"><li className="border-2 rounded-[2rem] border-white w-fit p-2">Zaloguj się do panelu</li></a>
        </ul>
        }
      </nav>
    );
  });
  
export default Navbar;