import React, { forwardRef } from 'react';
import { Button } from '../UI/Button';
import aboutImage1 from '../../static/aboutImage1.png';
import aboutImage2 from '../../static/aboutImage2.svg';


const Tile = forwardRef((props: any, ref: any) => {
    return (
      <div className="flex flex-col gap-2 p-4 pb-8 font-Lato bg-gray-900 rounded-[2rem]" >
        <h3 className='text-[1.75rem] lg:text-[2rem] font-semibold'> {props.header}</h3>
        <p className='text-[1.125rem] max-w-[600px]'>
            {props.text}

         </p>
         {props.reference ? 
         <Button text={props.buttonText}   reference={props.reference}/>
        
         :<Button text={props.buttonText}  onClick={props.handleOpen} />}
         <img src={props.type === 0 ? aboutImage1 : aboutImage2} className='mt-4 max-w-[600px] max-h-[350px] rounded-[32px]'/>
      </div>
    );
  });
  
  export default Tile;