
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import React, { forwardRef, Ref, RefObject, useEffect,FC,ReactNode } from 'react';
import { Button } from '../UI/Button';
import close from '../../static/close.svg';
import modalImage1 from '../../static/modalImage1.png';
import modalImage2 from '../../static/modalImage2.png';
import learnMore from '../../static/learnMore.png';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
const style = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '0%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height: "90%",
  bgcolor: '#010101',
  color: "#fff",
  border: '2px solid #000',
  boxShadow: 24,
  p: "0.5rem 2.5rem",
  borderRadius: "2rem 2rem 0  0",
  overflowY:'scroll',
  '@media (min-width: 1920px)': {
    width: '80%',
    left: '10%', 
  },
};

export default function StatsModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (

      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
            sx: {
              
              backgroundColor: 'rgba(53, 53, 53, 0.9)',
              backdropFilter: "blur(10px)",
              
            },
          },
        }}
        
      >
        <Slide in={props.open} direction="up">
        <Box sx={style} >
        
          <div className='flex flex-col gap-0'>
          <div className='flex justify-end sticky top-0 right-0 -mr-2'>
            <img src={close} className='sticky top-5 -right-2 hover:cursor-pointer bg-black rounded-full -mr-8 p-2' onClick={()=>props.handleClose()}/>
            </div>
            <div className='flex flex-col gap-16'>
            <div className='flex flex-col gap-2'>
                <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Poznaj moc prawdziwych danych</h2>
                <p className='text-[1.125rem] max-w-[42%]'>Nasze narzędzia analizują ilość skanowań produktów, pomagając Ci zrozumieć preferencje klientów w różnych kategoriach. Co jest na topie? Dowiedz się od nas!</p>
                <Button text={"Umów się na rozmowę"} onClick={()=>props.handleClose()} reference={props.contact}/>
            </div>
            <div className='flex flex-col lg:gap-28 lg:flex-row'>
                <div className='flex flex-col gap-8 lg:max-w-[45%]'>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Zbliż się do swoich Klientów</h4>
                    <p className='text-[1.125rem]'>Nasze narzędzia analizują ilość skanowań produktów, pomagając Ci zrozumieć preferencje klientów w różnych kategoriach. Co jest na topie? Dowiedz się od nas!</p>
                  </div>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Zrozum zaangażowanie</h4>
                    <p className='text-[1.125rem]'>Poprzez analizę aktywności użytkowników, takich jak rejestracja konta w celu dostępu do ukrytych treści, poznasz stopień ich zaangażowania i lojalności.</p>
                  </div>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Buduj Lojalność</h4>
                    <p className='text-[1.125rem]'>Dzięki naszej analizie NPS oraz narzędziom do budowania bazy użytkowników, zbudujesz trwałe i głębokie relacje z klientami, które pozostaną z Tobą na lata</p>
                  </div>
                </div>
                <img src={modalImage1} className='rounded-[32px]'/>
              </div>
            <div className='flex flex-col lg:gap-16 lg:flex-row'>
              <div className='flex items-center lg:w-[65%]'>
                <img src={modalImage2}  className='lg:w-[90%]'/>
              </div>
              
                <div className='flex flex-col gap-8 lg:max-w-[35%]'>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Twórz Armie Ambasadorów</h4>
                    <p className='text-[1.125rem]'>Zadowoleni klienci to Twoi najlepsi sprzymierzeńcy. Umożliw im stanie się prawdziwymi ambasadorami Twojej marki.</p>
                  </div>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Obserwuj trendy w czasie rzeczywistym</h4>
                    <p className='text-[1.125rem]'>Monitoruj ilość skanowań z podziałem na tygodnie i miesiące. Zauważ wzrosty, spadki i okresy stagnacji – reaguj na bieżąco!</p>
                  </div>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Wzmacniaj swoją pozycję na rynku!</h4>
                    <p className='text-[1.125rem]'>Nasze zaawansowane narzędzia analityczne dają Ci przewagę konkurencyjną. Stań się liderem w swojej branży.</p>
                  </div>
                  <div className='felx flex-col'>
                    <h4 className='text-[1.75rem]'>Rysuj swoją strategię sukcesu</h4>
                    <p className='text-[1.125rem]'>Korzystając z naszych narzędzi, nie tylko zrozumiesz swoich klientów, ale również wypracujesz strategie, które przyniosą długoterminowy sukces Twojemu biznesowi.</p>
                  </div>
                </div>
                
              </div>
              <div>
          <div className="flex flex-col gap-8 justify-center items-center font-Lato px-4 lg:px-10 py-28 text-center " >
            <div className='flex flex-col gap-2'>
                <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Chesz wiedzieć więcej?</h2>
                <p className='text-[1.125rem]'>Umów się na rozmowę z ekspertami i dowiedz się, jak na podstawie danych zdobyć znaczną przewagę konkurencyjną!</p>
            </div>

              <img src={learnMore} />
              <Button text={"Umów się na rozmowę"} onClick={()=>props.handleClose()} reference={props.contact}/>
            </div>

          </div>
          </div>
        </div>
          
        </Box>
        </Slide>
      </Modal>
  );
}