import React, { forwardRef } from 'react';
import {Button} from '../UI/Button';
import MainBg from '../../static/mainBg.png';
import mainBgMobile from '../../static/mainBgMobile.png';
import {Fade} from 'react-reveal';
const Main = forwardRef((props: any, ref: any) => {
    return (
      <div className="flex flex-col gap-20 lg:justify-center lg:items-center font-Lato lg:text-center lg:pt-28 pt-4" >
        <Fade duration={2000} bottom >
        <div className='flex flex-col gap-2 px-4 lg:px-10 lg:items-center'>
        <h2 className='font-bold text-[2.5rem] lg:text-[3.75rem]'>Zobacz więcej dzięki Adaptivo</h2>
            <p className='font-normal text-[1.375rem] mb-5 lg:max-w-[50%]'>Przełomowe narzędzie dla producentów i klientów, umożliwiające śledzenie ruchów produktów na całym świecie w czasie rzeczywistym.</p>
            <Button text="Dowiedz się, jak działa system!" reference={props.about}/>
        </div>
        </Fade>
        <Fade duration={2000} bottom delay={1000}>
            <img src={MainBg} className='hidden lg:block w-full fullHD:max-w-[80%]'/>
            <img src={mainBgMobile} className='block lg:hidden '/>
          </Fade>
      </div>
    );
  });
  
  export default Main;