import React, { forwardRef } from 'react';
import Step from './Step';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';
import {Button} from '../UI/Button';
const Steps = forwardRef((props: any, reference: any) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
    return (
      <div ref={ref}>
      <Fade bottom duration={1500}  when={inView} >
      <div className="flex flex-col gap-8 font-Lato px-4 xl:px-48 py-28 lg:items-center" >
        <div className='flex flex-col gap-4 lg:items-center'>
            <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Wystarczą trzy kroki...</h2>
            <p className='text-[1.125rem] lg:max-w-[55%] lg:text-center'>Zapomnij o poszukiwaniu zespołu programistów i budowaniu skomplikowanej logiki. System Adaptivo pomoże Ci zdobyć przewagę konkurencyjną i zautomatyzować czynności, które kiedyś trwały długie godziny!</p>
            <Button text="Odbierz dostęp do panelu Adaptivo" reference={props.contact}/>
        </div>

        <Step number={0} header={"Przygotuj spersonalizowane strony internetowe!"} text={"Przejdź przez proces tworzenia atrakcyjnych treści, dzięki generatorowi stron internetowych. Nie musisz umieć programować. Wystarczy, że uzupełnisz informacje o produkcie oraz dodasz swoje zdjęcia - to wszystko!"}/>
        <Step number={1} header={"Wygeneruj indywidualne adresy do kodów QR"} text={"Po przygotowaniu treści, następnym krokiem jest generowanie unikatowych kodów QR, które będą prowadzić do wybranych szablonów. Zrobisz to w zaledwie kilka kliknięć!"}/>
        <Step number={2} header={"Monitoruj aktywność swoich klientów w panelu zarządzania"} text={"Gdy produkt trafia w ręce klienta, może on skanować kod, aby otworzyć stronę mobilną. Prawdziwą siłą są dane, które są przesyłane do systemu. Dzięki zaawanzowanej analityce dowiesz się jeszcze więcej!"}/>
      </div>
      </Fade>
      </div>
    );
  });
  
  export default Steps;