import React, { forwardRef } from 'react';
import { Button } from '../UI/Button';
import aboutClient from '../../static/aboutClient.png';
import Qrcode from '../../static/Qrcode.png';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';
const Clients = forwardRef((props: any, ref: any) => {
  const { ref: reference, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
   
    return (
      <div ref={ref}>
      <div ref={reference}>
      <Fade bottom duration={1500}  when={inView} >
      <div className="flex flex-col  gap-4 lg:justify-center lg:items-center font-Lato px-4 lg:px-10 py-8 bg-gray-900 rounded-[2rem] relative" >
        <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold mb-2'>Co zobaczą Twoi klienci?</h2>
        <div className='flex flex-col gap-8 lg:flex-row lg:justify-center'>
            <div className='flex flex-col gap-4 lg:order-2 lg:max-w-[40%]'>
                
                <p className='text-[1.125rem]'>Po zeskanowaniu kodu QR z etykiety produktu zapisane zostaną liczne informacje. Twoi klienci zobaczą jednak specjalną stronę internetową dedykowaną Twojemu produktowi.</p>
                <p className='text-[1.125rem]'>W dzisiejszym świecie, tworzenie spersonalizowanych i atrakcyjnych treści jest kluczem do skutecznego docierania do klienta. </p>
                <p className='text-[1.125rem]'>W systemie Adaptivo zautomatyzujesz tworzenie mobilnych stron internetowych dla wszystkich produtków.</p>
                <Button text={"Sprawdź demo panelu Adaptivo"} reference={props.contact}/>
            </div>
            <img src={aboutClient} className='lg:order-1 rounded-[32px]'/>
            <img src={Qrcode} className='hidden absolute right-16 bottom-48 lg:block'/>
        </div>
      </div>
      </Fade>
      </div>
      </div>
      
    );
  });
  
  export default Clients;