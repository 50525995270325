import React, { forwardRef, useState, useEffect } from 'react';
import { Button } from '../UI/Button';
import Form from './Form';
import Qrcode from '../../static/Qrcode.png';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';


const Contact = forwardRef((props: any, ref: any) => {
    const { ref: reference, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,

      });


    return (
        <div ref={ref} >
        <div ref={reference} >
        <Fade bottom duration={1500}  when={inView} >
      <div className="flex flex-col gap-8 xl:justify-center xl:items-center font-Lato px-4 py-4 bg-gray-900 rounded-[2rem] relative xl:px-56 xl:py-20" >
        <div className='flex flex-col xl:justify-center xl:items-center xl:w-[1100px]'>
            <div className='flex flex-col justify-start xl:items-start'>
            <h2 className='text-[2.5rem] xl:text-[3.75rem] font-bold mb-2 xl:max-w-[70%] leading-[120%]'>Umów się na bezpłatną prezentację Adaptivo!</h2>
            <div className='flex flex-col gap-8 xl:flex-row '>
                <div className='flex flex-col gap-8 xl:max-w-[27%]'>
                    <div className='flex flex-col'>
                        <h4>Krok 1</h4>
                        <p>Uzupełnij formularz i przedstaw najważniejsze informacje o Twojej branży i produkcie.</p>
                    </div>
                    <div className='flex flex-col'>
                        <h4>Krok 2</h4>
                        <p>Poczekaj na kontakt z biura Adaptivo. Nasi eksperci umówią się z Tobą na prezentacje produktu.</p>
                    </div>
                    <div className='flex flex-col'>
                        <h4>Krok 3</h4>
                        <p>Sprawdź możliwości systemu i automatyzuj pracę marketingu</p>
                    </div>
                </div>
                <Form />
            </div>
            </div>
            
        </div>
        <img src={Qrcode} className='hidden absolute left-24 bottom-48 max-w-[106px] xl:block'/>
      </div>
      </Fade>
      </div>
      </div>
    );
  });
  
  export default Contact;